@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans"),
    url("./assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans"),
    url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}


html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;

}

h1,
h2,h3, h4, h5 {
  font-family: "Uni Sans";
  font-weight: 900;
}

p{
  font-family: "Uni Sans";
}

#section-1 {
  background-image: url("./assets/images/section-1.png");
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: normal;
  background-size: cover;
}


.blur-ball-left {
  position: absolute;
  width: 1203px;
  height: 1203px;
  left: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}

.blur-ball-right {
  position: absolute;
  width: 1203px;
  height: 1203px;
  right: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}
